$platform-name: 'walthamabbeyroyalgunpowdermills';

//
// Colours
//

$brand-primary: #000;
$brand-secondary: #d0001f;

// $donate-colour: $brand-secondary;

// Create greyscale
$black: #000 !default;
$grey-dark: #292b2c !default;
$grey: #464a4c !default;
$grey-light: #636c72 !default;
$grey-lighter: #eceeef !default;
$grey-lightest: #f7f7f9 !default;
$white: #fff !default;

//
// Layout
//

$container-max-width: 1360px;

$border-radius: 5px;

// Spacers
$spacer: 1rem !default;
$spacer-y: $spacer !default;
$spacer-x: $spacer !default;

//
// Logo
//

// $logo: "logo.svg";
$logo-width: 200px;
$logo-height: 160px;

//
// Typography
//

$font-family-base: "Raleway", sans-serif;

$headings-font-family: "Bitter", serif;

//
// Menu admin
//

$menu-admin-enabled: false;

//
// Header
//

// Tagline
// $tagline-enabled: true;

//
// Card
//

// Event cards
$event-card-floating-date: true;
$event-card-floating-date-background-colour: $brand-secondary;

//
// Navigation
//

$nav-background-colour: #fff;
$nav-text-transform: none !default;

// Top level items
$nav-top-level-chevrons-enabled: true;
$nav-top-level-item-font-weight: bold;

// Nav normal
$nav-normal-align-items: right;

//
// Buttons
//

$btn-font-family: $headings-font-family;

//
// Carousel
//

$carousel-max-width: 100%;
$carousel-details-background-colour: rgba($brand-secondary, .8);
$carousel-details-max-width: 500px;
$carousel-details-position-y: center;
$carousel-details-padding: 3rem;
$carousel-details-margin-x: 40px;

$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: 20px;
$carousel-controls-button-background-colour: $carousel-details-background-colour;
$carousel-controls-icon-colour: $brand-primary;

$carousel-heading-colour: #fff;

//
// Home intro
//

$home-intro-margin-top: 0;
$home-intro-background-colour: #fff;

//
// Home features
//

$home-features-padding-top: 0;
$home-features-padding-bottom: 2.5rem;

//
// Home feeds
//

$home-feeds-padding-top: 2.5rem;
$home-feeds-padding-bottom: 2.5rem;

//
// Home quick giving panel
//

$home-quick-giving-type: overlay;

//
// Donation form
//

$donation-form-frequency-tabs: true;

//
// Footer
//

$footer-boxes-count: 3;
$footer-prefab: 3;
$footer-background-colour: #000;


// Newsletter
$newsletter-background-colour: $brand-secondary;
$newsletter-button-background-colour: $brand-primary;
$newsletter-use-placeholders: true;
//
// Slick
//

$slick-enabled: true;

//
// Sidebar
//

$sidebar-adjacent-width: 300px !default;
$sidebar-adjacent-gap: $spacer * 5 !default;

//
// Listing
// 

// Listing page intro width
// width: calc(#{$sidebar-adjacent-width} + #{$sidebar-adjacent-gap});

$listing-intro-max-width: calc(#{$container-max-width} - (#{$sidebar-adjacent-width} + #{$sidebar-adjacent-gap}));