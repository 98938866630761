// Social icons under tagline
.tagline .socialIcons {
  margin-top: 10px;
}

// Home intro
.homeIntro p {
  font-family: $font-family-base;
}

// Shift nav up
@media (min-width: 1100px) {
  #menuMain {
    margin-top: -50px;
    padding-bottom: 10px;
  }

  .mainCallToAction,
  .header-search {
    margin-top: -20px !important;
  }
}

@media (max-width: 1200px) {
  .mainLogo {
    height: 120px;
  }
}

// Carousel button colour
@media (min-width: map-get($breakpoints, lg)+1) {
  .carousel {

    .cta-button.donate,
    .button.donate,
    button.donate {
      background-color: #000;
    }
  }
}

// Search height
.header-search #siteSearch {
  height: 40px;
}

// Button hover stuff
.button,
.cta-button,
button,
.carouselSlideReadMore,
.shareList li a,
.socialIcons li a {
  box-shadow: 0;
  transition: all 150ms ease-in-out;

  &:hover {
    box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.25) !important;
    transform: scale(1.05);
  }
}

// Feature and feed image blur on hover
.homeFeature:not(.homeFeatureSnippets),
.feedItem {
  img {
    transition: all 100ms ease-in-out;
  }

  &:hover img {
    filter: blur(5px);
  }
}

// Sidebar
.postFeeds h2 {
  background-color: $brand-secondary;
  color: #fff;
  padding: 0.5rem;
  border-radius: $border-radius $border-radius 0 0;
  margin-bottom: 0;

  * {
    color: #fff;
  }
}

.associatedBox {
  background-color: $grey-lightest;
  border-radius: $border-radius;

  a:first-child {
    margin-bottom: 0;
    border-radius: $border-radius $border-radius 0 0;
  }

  h2 {
    border-radius: 0;
    margin-bottom: 0;
  }

  .associatedSummary {
    padding: 0.5rem;
  }

  .button {
    margin: auto;
  }
}

.associatedLatestPosts,
.associatedMostRead,
.associatedRelatedPosts {
  background-color: $grey-lightest;
  border-radius: $border-radius;

  ul {
    padding: 0.5rem;
  }
}

// Footer
.pageFooter a:not(.button) {
  color: #fff;
}

.Footercredits img {
  margin: 20px;
}

// Home Box 1
.homeBox1 {
  margin: 0;
  max-width: $container-max-width;
  margin: auto;
  @media (min-width: 1025px) {
    .homeFeatureDetailsWrapper {
      position: absolute;
      max-width: 420px;
      top: 50%;
      transform: translatey(-50%);
      left: 20px;
      border-radius: $border-radius;
    }
  }
}

// Home features
.homeFeatures2Wrapper {
  width: 100%;
  background-color: $grey-lightest;
  padding-top: 2.5rem;
}

// Home quick giving
@media (min-width: map-get($breakpoints, lg)+1) {
  .homefeaturecategory-homeboxquickgiving h2 {
    color: #fff;
  }
}

// Menu button
.menuMainAlt {
  font-size: 1.1rem;
}

// Menu item outline
#menuMain li a:focus {
    outline: 2px solid #104F40;
}


//  Mailchimp
#mc_embed_signup_scroll {
  max-width: 790px;
  margin: 0 auto;
  padding: 0 $site-bleed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: map-get($breakpoints, sm)) {
    flex-direction: column;
  }
  label {
    display: block;
    width: 100%;
    flex: unset;
    font-family: $headings-font-family;
    font-size: $newsletter-heading-font-size;
    text-align: $newsletter-heading-text-align;
    font-weight: $headings-font-weight;
    text-transform: $newsletter-heading-text-transform;
    margin-bottom: $newsletter-heading-margin-bottom;
  }
  input:not(#mc-embedded-subscribe) {
    flex-grow: 1;
    margin-top: #{$spacer * 0.25};
    margin-bottom: #{$spacer * 0.25};
    min-width: 275px;
    @media (min-width: map-get($breakpoints, sm) + 1px) {
      margin-right: $spacer;
    }
    @media (max-width: map-get($breakpoints, sm)) {
      width: 100%;
      &::placeholder { text-align: center; }
    }
  }
  #mc-embedded-subscribe {
    margin-top: #{$spacer * 0.5};
    margin-bottom: #{$spacer * 0.25};
    background-color: $newsletter-button-background-colour;
    color: $newsletter-button-colour;
    min-height: $newsletter-button-height;
  }
}